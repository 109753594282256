import React, { useEffect, useState } from 'react';
import TonWeb from 'tonweb';
import { useTonWallet, useTonAddress } from '@tonconnect/ui-react';

const WalletBalance = () => {
    const wallet = useTonWallet();  // Хук для получения данных о кошельке
    const [balance, setBalance] = useState(null);
    const [balanceNanoTon, setbalanceNanoTon] = useState(null);
    const userFriendlyAddress = useTonAddress();
    const rawAddress = useTonAddress(false);
    useEffect(() => {
        const fetchBalance = async () => {
            if (wallet && userFriendlyAddress) {
                try {
                    const tonweb = new TonWeb();  // Инициализируем TonWeb
                    const walletAddress = new TonWeb.utils.Address(rawAddress);

                    setbalanceNanoTon(await tonweb.provider.getBalance(walletAddress));
                    const balanceTon = TonWeb.utils.fromNano(balanceNanoTon);  // Переводим из нанотонов в тонны
                    
                    setBalance(balanceTon);  // Сохраняем баланс
                } catch (error) {
                    console.error('Ошибка при получении баланса:', error);
                }
            }
        };

        fetchBalance();
    }, [wallet]);

    return (
        <div>
            <p>Balance: {balance} TON</p>
            {balance !== null ? (
                <p>Balance: {balance} TON</p>
            ) : (
                <p>Loading balance...</p>
            )}
             <p>walletAddress: {balanceNanoTon} </p>
             {userFriendlyAddress && (
      <div>
        <span>User-friendly address: {userFriendlyAddress}</span>
        <span>Raw address: {rawAddress}</span>
      </div>
    )}
        </div>
    );
};

export default WalletBalance;
