import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
const Back = () => {

    const navigate = useNavigate();

    const back = () => {

        navigate(-1); //возращает на одну страницу назад
    }
    
    return (
        <div className="back-container">
            <div className="">  
                <button 
                    onClick={back}
                    type="button" 
                    >
                        <FontAwesomeIcon icon={faAngleLeft} size="xl" />
                </button> 
            </div>
        </div>
        
        );
    };
    
    export default Back;