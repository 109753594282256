import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import "./main.css";
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { TokenProvider } from './TokenContext';

import Home from './Home';
import Profile from './Profile';
import Stats from './Stats';
import { Buffer } from 'buffer';
window.Buffer = Buffer;

function App() {
  
  const [isDark, setIsDark] = useState(false);


  const toggleTheme = () => setIsDark(!isDark);

  useEffect(() => {
  if (window.Telegram && window.Telegram.WebApp) {
      // Расширяем мини-приложение до максимальной высоты
      // window.Telegram.WebApp.expand();

      // Функция для обновления темы в зависимости от данных Telegram
      const updateTheme = () => {
      const themeData = window.Telegram.WebApp.colorScheme;
      if (themeData) {
          // Проверяем параметр bg_color или другой параметр, определяющий тему
          // Это просто пример, вам нужно будет адаптировать логику под ваш случай
          setIsDark(themeData === 'dark');
      }
      };

      // Слушатель для обновления темы
      window.Telegram.WebApp.onEvent('themeChanged', updateTheme);

      // Вызов updateTheme при инициализации для установки начальной темы
      updateTheme();

      // Очищаем слушатель при размонтировании компонента
      return () => {
      window.Telegram.WebApp.offEvent('themeChanged', updateTheme);
      };
  }
  }, []);



  return (
    <TonConnectUIProvider 
    manifestUrl="https://thapool.com/sprinttap/tonconnect-manifest.json" //http://192.168.0.105:3000
    actionsConfiguration={{
      twaReturnUrl: 'https://t.me/ThaPoolBot>'
  }}
    >
        <TokenProvider>
          <BrowserRouter>
            <div className={`App ${isDark ? 'theme-dark' : 'theme-light'}`}>
              <div className={`${isDark ? 'col--dark' : ''}`}>
                <Routes>
                      <Route path="/" element={<Home key={Home} isDark={isDark} toggleTheme={toggleTheme} />}/>
                      <Route path="/profile" element={<Profile key={Profile} />} />
                      <Route path="/stats" element={<Stats key={Stats} />} />
                    </Routes>
                  
              </div>
            </div>
        </BrowserRouter>
      </TokenProvider>
    </TonConnectUIProvider>

  );
}

export default App;

//ip: 147.45.151.58
