import Back from './Back';
const Stats = () => {

    return (
        <div className="col main-container">
            <Back/>
            <div className="waving">
                    <label style={{ fontSize: '2rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}>
                        Soon...
                    </label>
                </div>
        </div>
        
        );
    };
    
    export default Stats;