import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRoute,
} from "@fortawesome/free-solid-svg-icons";
const ProgressBar = ({ total, current, label })  => {

    const progress = (current / total) * 100;
  
    return (
        <div className="progress-bar-container">
            <div className="row" >
                <FontAwesomeIcon className="mt-5 mr-1" icon={faRoute} size="sm" />
                <div className="waving">
                    {(current.toFixed(2) + " / " + total).toLocaleString().split('\b').map((char, index) => (
                        <span key={index} style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}>
                            {char}
                        </span>
                    ))}
                {/* <span  style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}>
                    <div dangerouslySetInnerHTML={{__html: '&nbsp;  / &nbsp;'}} />
                </span> */}
                </div>
            </div>
            

            <div className="progress-container">
                <div className="progress-bar" style={{ width: `${progress}%` }}>
                </div>
            </div>
      </div>

    );
  }
  export default ProgressBar;