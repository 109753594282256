import React, { useState, useEffect, useRef } from 'react';
import Back from './Back';
import WalletBalance from './WalletBalance';
import { useToken } from './TokenContext';
import TonWeb from 'tonweb';
import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';

const Profile = () => {
    const [balance, setBalance] = useState(0);
    // const wallet = useTonWallet();
    // const tonweb = new TonWeb(); // Создайте экземпляр TonWeb

    // const fetchBalance = async (address) => {
    //     try {
    //         const walletContract = tonweb.wallet.create({ address });
    //         const balance = await tonweb.provider.getBalance(address);
    //         setBalance(balance.toString());  // Установите полученное значение баланса
    //     } catch (error) {
    //         console.error('Ошибка при получении баланса:', error);
    //     }
    // };

    // useEffect(() => {
    //     if (wallet) {
    //         fetchBalance(wallet.account.address); // Получите баланс, если кошелек подключен
    //     }
    // }, [wallet]); // Запустите эффект при изменении кошелька



    // const { token } = useToken();

    // const [cryptoAddress, setCryptoAddress] = useState('');
    // const [message, setMessage] = useState('');

    // const [isPressedSave, setIsPressedSave] = useState(false);
    // const [timerSave, setTimerSave] = useState(null);
    // const [isPressedEdit, setIsPressedEdit] = useState(false);
    // const [timerEdit, setTimerEdit] = useState(null);
    
    // const [isEditing, setIsEditing] = useState(true);

    // const fetchAddress = async () => {
    //     try {
    //       const response = await fetch(`https://utxobot.site/api/save-address`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization': `Bearer ${token}` 
    //             },
    //         });
    //       if (response.ok) {
    //           const data = await response.json();
    //           setCryptoAddress(data.cryptoAddress);
    //           // Если адрес не пустой, блокируем редактирование
    //           setIsEditing(!data.cryptoAddress);
    //           setMessage('');
    //       } else {
    //         const errorStatus = await response.status;
    //         setMessage(`Ошибка: ${errorStatus}, при запросе на сервер.`);
    //       }
    //     } catch (error) {
    //       setMessage(`Ошибка соединения с сервером: ${error.message}`);
    //     }
    //   };

    // useEffect(() => {
    //     if (token) { // Убедитесь, что userId не пустой
    //         fetchAddress();
    //     } else {
    //         setMessage('Токен не найден. Пожалуйста, попробуйте перезапустить веб-приложение.');
    //     }
    //     }, [token]);
        
    // const handleInputChange = (event) => {
    //     setCryptoAddress(event.target.value);
    // };
  
    // const handleSubmit = async (event) => {
    //     if (timerSave) clearTimeout(timerSave);
    //       setIsPressedSave(true); 
    //       const newTimer = setTimeout(() => {
    //         setIsPressedSave(false);
    //       }, 100);
      
    //       setTimerSave(newTimer); 

    //     event.preventDefault();
    //     try {
    //     const response = await fetch(`https://utxobot.site/api/save-address`, {
    //         method: 'POST',
    //           headers: {
    //               'Content-Type': 'application/json',
    //               'Authorization': `Bearer ${token}`
    //           },
    //         body: JSON.stringify({ cryptoAddress: cryptoAddress }),
    //     });

    //     if (response.ok) {
    //         setMessage('Address saved successfully');
    //         setIsEditing(false);
    //     } else {
    //         setMessage('Failed to save address');
    //     }
    //     } catch (error) {
    //     setMessage(`Error: ${error.message}`);
    //     }
    // };

    // const handleEdit = (event) => {
    //     if (timerEdit) clearTimeout(timerEdit);
    //       setIsPressedEdit(true); 
    //       const newTimer = setTimeout(() => {
    //         setIsPressedEdit(false);
    //       }, 100);
      
    //       setTimerEdit(newTimer);

    //     event.preventDefault();
    //     setIsEditing(true);
    // };

    return (
        <div className="col main-container">
            <Back/>
            {/* <form onSubmit={handleSubmit}  style={{ width: '90%', maxWidth: '90%' }}>
                <div className=" ">
                <div className="waving">
                    <label htmlFor="cryptoAddress" style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}>
                    Your THA address:
                    </label>
                </div>
                
                <input
                className="input-settings"
                    type="text"
                    id="cryptoAddress"
                    name="cryptoAddress"
                    value={cryptoAddress}
                    onChange={handleInputChange}
                    required
                    disabled={!isEditing}
                />
                <div className="container-settings waving" >  
                {isEditing ? (
                            <button 
                                className="btn-settings btn-settings-save" 
                                type="submit" 
                                aria-pressed={isPressedSave ? 'true' : 'false'}
                                style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}
                            >
                                <span className="btn-settings__sr">Save</span>
                                Save
                            </button>
                        ) : (
                            <button 
                                className="btn-settings btn-settings-save" 
                                type="button" 
                                onClick={handleEdit}
                                aria-pressed={isPressedEdit ? 'true' : 'false'}
                                style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}
                            >
                                <span className="btn-settings__sr">Edit</span>
                                Edit
                            </button>
                        )}
                    </div>
                </div>
                
            </form> */}
            <div className="header-container" >
                <div className="waving">
                    {("balance: ").toLocaleString().split('\b').map((char, index) => (
                                        <span key={index} style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}>
                                            {char}
                                        </span>
                    ))}
                    {balance.toFixed(2).toLocaleString('en-US').split('').map((num, index) => (
                        <span key={index} >
                            {num}
                        </span>
                    ))}
                </div>    
            </div>
            <div className="toggle-container">
                <TonConnectButton/>
            </div>
            {/* {message && <p>{message}</p>} */}

        </div>
        
        );
    };
    
    export default Profile;