import React, { useState, useEffect, useRef } from 'react';
import SpeedIndicator from './SpeedIndicator';
import StadiumAnimation from './StadiumAnimation';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStopwatch,faGaugeHigh,faPersonRunning,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from './ProgressBar';
import logo from "./icons/logoTHA.svg";
import track from "./icons/background-image11.png";
import running from "./icons/running.png";
import running2 from "./icons/running2.svg";

import start from "./icons/start.png";
import finish from"./icons/finish.png";
import { useToken } from './TokenContext';
const runImages = [running, running2];
const Home = ({isDark, toggleTheme}) => {
  const [distance, setDistance] = useState(0);
  const [startTime, setStartTime] = useState(null); // добавляем для отсчета времени
  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [currentSpeed, setCurrentSpeed] = useState(1); // начальная скорость
  const accelerationPerTap = 0.5; // ускорение на каждый тап
  const distanceGoal = 100; // финишная дистанция
  const [isRunning, setIsRunning] = useState(false);// Добавляем состояние для управления забегом
  const [isFinish, setIsFinish] = useState(false);
  const [position, setPosition] = useState(0); // Позиция бегунка
  useEffect(() => {
    let interval;
    if (isRunning && distance < distanceGoal) {
      // При начале отсчета сохраняем текущее время
      if (!startTime) setStartTime(performance.now());
      interval = setInterval(() => {
        setDistance(prevDistance => {
          // const newDistance = prevDistance + currentSpeed * 0.1;
          const currentTime = performance.now();
          const elapsedTime = (currentTime - lastUpdateTime) / 1000;

          const newDistance = prevDistance + (elapsedTime * currentSpeed);
          
          setTimeElapsed((currentTime - startTime) / 1000);
          setLastUpdateTime(currentTime);
          if (newDistance >= distanceGoal) {
            clearInterval(interval);
            setIsRunning(false);
            return distanceGoal;
          }
          return newDistance;
        });
        // Обновляем время на основе начального времени и текущего
        // setTimeElapsed((performance.now() - startTime) / 1000);
        
      }, 10);
    }else if (distance >= distanceGoal) {
      setIsRunning(false);
      setIsFinish(true);
    }
    return () => clearInterval(interval);
  }, [isRunning, currentSpeed, distance, startTime, lastUpdateTime]);

  const handleStart = (event) => {
    const currentTime = performance.now();
    setStartTime(currentTime); // Обнуление времени на старте
    setLastUpdateTime(currentTime);
    setIsRunning(true); // Запуск забега
    // setPosition(0); // Сбрасываем позицию бегунка
  };

  const handleTap = (event) => {
    if (!isRunning) return;
    if (distance < distanceGoal) {
      setCurrentSpeed(prevSpeed => prevSpeed + accelerationPerTap);
      handleInteraction(event);
    }
  };

  // const handleTap = (event) => {
  //   if (!isRunning) return;
  //   if (distance < distanceGoal) {
  //     const speed = calculateSpeed(position); // Получаем скорость в зависимости от позиции
  //     setCurrentSpeed(prevSpeed => prevSpeed + speed); // Увеличиваем текущую скорость
  //     setPosition(0); // Сбрасываем позицию бегунка
  //     handleInteraction(event); // Обрабатываем взаимодействие
  //   }
  // };

  const calculateSpeed = (pos) => {
    const zone = Math.floor(pos / 11.11); // 100 / 9 = 11.11
    switch (zone) {
      case 0: return 0.1; // Красная зона
      case 1: return 0.2; // Оранжевая зона
      case 2: return 0.3; // Желтая зона
      case 3: return 0.4; // Желто-зеленая зона
      case 4: return 0.5; // Зеленая зона
      case 5: return 0.4; // Желто-зеленая зона (обратная)
      case 6: return 0.3; // Желтая зона (обратная)
      case 7: return 0.2; // Оранжевая зона (обратная)
      case 8: return 0.1; // Красная зона (обратная)
      default: return 0.1; // На всякий случай
    }
  };


  // const handleSpeedChange = (speed) => {
  //   setCurrentSpeed(prevSpeed => prevSpeed + speed); // Увеличиваем скорость
  // };
  



    const { token, setToken } = useToken();
  const [animations, setAnimations] = useState([]);

  //анимация клика
  const [isPressed, setIsPressed] = useState(false);
  const [isPressedSettingsProfile, setIsPressedSettingsProfile] = useState(false);
  const [isPressedSettingsStats, setIsPressedSettingsStats] = useState(false);


  const [timer, setTimer] = useState(null);
  const [timerSettingsProfile, setTimerSettingsProfile] = useState(null);
  const [timerSettingsStats, setTimerSettingsStats] = useState(null);

  const [clicks, setClicks] = useState(0);
  const [life, setLife] = useState(1000);

  const [error, setError] = useState('');
  
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = queryParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    }
  }, [setToken]);

  const fetchClicks = async () => {
    try {
      const response = await fetch(`https://utxobot.site/api/clicks/${token}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}` 
            },
        });
      if (response.ok) {
          const data = await response.json();
          setClicks(data.clicks);
          setLife(data.life);
          setError('');
      } else {
        const errorStatus = await response.status;
        setError(`Ошибка: ${errorStatus}, при запросе на сервер.`);
      }
    } catch (error) {
      setError(`Ошибка соединения с сервером: ${error.message}`);
    }
  };

    useEffect(() => {
    if (token) { // Убедитесь, что userId не пустой
        fetchClicks();
    } else {
        // setError('Токен не найден. Пожалуйста, попробуйте перезапустить веб-приложение.');
    }
    }, [token]);

    const sendClick = async () => {
        if(life > 0){
          setLife(life => life - 1);
        }
        
        setClicks(prevClicks => prevClicks + 1);
        try {
          const response = await fetch(`https://utxobot.site/api/clicks/${token}`, {//localhost   178.253.40.190   https://utxobot.site
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${token}`
              },
              body: JSON.stringify({ count: 1 })
          });
          if (!response.ok) {
            const errorStatus = await response.status;
            setError(`Ошибка: ${errorStatus}, нет ответа от сервера.`);
          }
        } catch (error) {
          setError(`Ошибка соединения с сервером: ${error.message}`);
        }
      };
      
      const handleInteraction = (event) => {
        if (distance < distanceGoal) {
          if (timer) clearTimeout(timer);
          setIsPressed(true); 
          const newTimer = setTimeout(() => {
            setIsPressed(false);
          }, 130);
      
          setTimer(newTimer); 
      
          // Определение координат касания или клика
          const touchEvent = event.touches ? event.touches[0] : event;
          const { clientX, clientY } = touchEvent;
      
          handleAnimation(clientX, clientY);
        }
      };
      
      const openProfile = (event) => {
        if (timerSettingsProfile) clearTimeout(timerSettingsProfile);
        setIsPressedSettingsProfile(true); 
        const newTimer = setTimeout(() => {
          setIsPressedSettingsProfile(false);
        }, 100);
        setTimerSettingsProfile(newTimer);

        navigate('/profile');
      };
      
      const openStats = (event) => {
        if (timerSettingsStats) clearTimeout(timerSettingsStats);
        setIsPressedSettingsStats(true); 
        const newTimer = setTimeout(() => {
          setIsPressedSettingsStats(false);
        }, 100);
        setTimerSettingsStats(newTimer);

      
        navigate('/stats');
      };
      
      const handleAnimation = (x, y) => {
        const newAnimation = {
          id: Math.random(), // уникальный ID для ключа
          style: {
            left: x + 'px',
            top: y + 'px'
          }
        };
        setAnimations(current => [...current, newAnimation]);
        setTimeout(() => {
          setAnimations(current => current.filter(anim => anim.id !== newAnimation.id));
        }, 3000); // Удаляем анимацию из массива после завершения
      };

      const buttonText = isRunning ? 'Go!' : isFinish ? 'Finish' : 'Start';
    return (
        <div className="col main-container">
            <div className="toggle-container">  
            <input
                            type="checkbox"
                            className="toggle-input"
                            id="darkmode-toggle"
                            checked={isDark}
                            onChange={toggleTheme}
                          />
                          <label htmlFor="darkmode-toggle" className="toggle-label">
                            <div className="sun"> 
                              <svg version="1.1" className="sun" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 496 496">

                              <rect x="152.994" y="58.921" transform="matrix(0.3827 0.9239 -0.9239 0.3827 168.6176 -118.5145)" width="40.001" height="16" />
                              <rect x="46.9" y="164.979" transform="matrix(0.9239 0.3827 -0.3827 0.9239 71.29 -12.4346)" width="40.001" height="16" />
                              <rect x="46.947" y="315.048" transform="matrix(0.9239 -0.3827 0.3827 0.9239 -118.531 50.2116)" width="40.001" height="16" />

                              <rect x="164.966" y="409.112" transform="matrix(-0.9238 -0.3828 0.3828 -0.9238 168.4872 891.7491)" width="16" height="39.999" />

                              <rect x="303.031" y="421.036" transform="matrix(-0.3827 -0.9239 0.9239 -0.3827 50.2758 891.6655)" width="40.001" height="16" />

                              <rect x="409.088" y="315.018" transform="matrix(-0.9239 -0.3827 0.3827 -0.9239 701.898 785.6559)" width="40.001" height="16" />

                              <rect x="409.054" y="165.011" transform="matrix(-0.9239 0.3827 -0.3827 -0.9239 891.6585 168.6574)" width="40.001" height="16" />
                              <rect x="315.001" y="46.895" transform="matrix(0.9238 0.3828 -0.3828 0.9238 50.212 -118.5529)" width="16" height="39.999" />
                              <path d="M248,88c-88.224,0-160,71.776-160,160s71.776,160,160,160s160-71.776,160-160S336.224,88,248,88z M248,392
                                  c-79.4,0-144-64.6-144-144s64.6-144,144-144s144,64.6,144,144S327.4,392,248,392z" />
                              <rect x="240" width="16" height="72" />
                              <rect x="62.097" y="90.096" transform="matrix(0.7071 0.7071 -0.7071 0.7071 98.0963 -40.6334)" width="71.999" height="16" />
                              <rect y="240" width="72" height="16" />

                              <rect x="90.091" y="361.915" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 -113.9157 748.643)" width="16" height="71.999" />
                              <rect x="240" y="424" width="16" height="72" />

                              <rect x="361.881" y="389.915" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 397.8562 960.6281)" width="71.999" height="16" />
                              <rect x="424" y="240" width="72" height="16" />
                              <rect x="389.911" y="62.091" transform="matrix(0.7071 0.7071 -0.7071 0.7071 185.9067 -252.6357)" width="16" height="71.999" />
                              </svg> 
                              </div>
                            <div className="moon"> 
                            <svg version="1.1" className="moon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 49.739 49.739">
                              <path d="M25.068,48.889c-9.173,0-18.017-5.06-22.396-13.804C-3.373,23.008,1.164,8.467,13.003,1.979l2.061-1.129l-0.615,2.268
                                c-1.479,5.459-0.899,11.25,1.633,16.306c2.75,5.493,7.476,9.587,13.305,11.526c5.831,1.939,12.065,1.492,17.559-1.258v0
                                c0.25-0.125,0.492-0.258,0.734-0.391l2.061-1.13l-0.585,2.252c-1.863,6.873-6.577,12.639-12.933,15.822
                                C32.639,48.039,28.825,48.888,25.068,48.889z M12.002,4.936c-9.413,6.428-12.756,18.837-7.54,29.253
                                c5.678,11.34,19.522,15.945,30.864,10.268c5.154-2.582,9.136-7.012,11.181-12.357c-5.632,2.427-11.882,2.702-17.752,0.748
                                c-6.337-2.108-11.473-6.557-14.463-12.528C11.899,15.541,11.11,10.16,12.002,4.936z" />
                            </svg>
                            </div>
                          </label>
                        </div>
                        <div>
                          {error && <p className="errorNotification rounded-xl">{error}</p>}
                        </div>
                        <div className="nav-link">
                        <FontAwesomeIcon className="mt-4 mr-2" icon={faStopwatch} size="2xl"/>
                          {/* <img width="40" className="mt-4 mr-2" src={logo} alt="logo"></img> */}
                          <div className="waving">
                          {timeElapsed.toFixed(2).toLocaleString('en-US').split('').map((num, index) => (
                            <span key={index} >
                              {num}
                            </span>
                          ))}
                        </div>
                        </div>
                        <ProgressBar total={distanceGoal} current={distance} /> 
                        <div className="row speed-container" >
                            <FontAwesomeIcon className="mt-5 mr-1" icon={faGaugeHigh} size="sm" />
                            <div className="waving">
                                {(currentSpeed.toFixed(2) + " m/s").toLocaleString().split('\b').map((char, index) => (
                                    <span key={index} style={{ fontSize: '1rem', textShadow: '0px 1px 0px var(--text-shadow-color), 0px 1px 1px rgba(0,0,0,.3)' }}>
                                        {char}
                                    </span>
                                ))}
                            </div>
                        </div>
                        
                        <div className="race-track">
                            <div className="background-container" 
                            style={{
                              transform: `translate(${-(19 + distance + (distance * 0.065))}em, -75em) rotateX(50deg)`, // Двигаем фон влево
                              transition: isRunning ? `transform 0s` : `none` // Обновляем без скачков
                            }}
                            // style={{
                            //   animationDuration: `${distanceGoal}s`,
                            //   animation: isRunning ? `move-image ${distanceGoal / currentSpeed}s linear infinite` : 'none',
                            
                            // }}
                            ></div>
                          <div className="runner-container">
                            <div  className={`runner ${isRunning ? 'running' : isFinish ? 'finish' : 'start'}`} id="runner" src={isRunning ? running : isFinish ? finish : start} style={{ backgroundImage: start, animationDuration: `${1 / currentSpeed}s`,}}></div>
                            </div>
                        </div>
                        {/* <SpeedIndicator 
                          isRunning={isRunning} 
                          setPosition={setPosition} 
                          position={position}
                        /> */}
                        <button 
                          onPointerDown={isRunning ? handleTap : handleStart} 
                          id="btn1" 
                          className="logo2 no-margin"
                          type="button" 
                          aria-pressed={isPressed ? 'true' : 'false'}>
                            <div className="waving">
                              {buttonText.split('').map((char, index) => (
                                <span key={index}>
                                  {char}
                                </span>
                              ))}
                            </div>
                          
                        </button>
                        {animations.map(anim => (
                        <div key={anim.id} className="animate" style={anim.style}>
                          <div>{accelerationPerTap}</div>
                        </div>
                        ))} 
                        
                        <div className="container-settings">  
                          <button 
                            onClick={openProfile}
                            className="btn-settings btn-settings-profile" 
                            type="button" 
                            aria-pressed={isPressedSettingsProfile ? 'true' : 'false'}>
                            <span className="btn-settings__sr">Profile</span>
                            
                          </button>  
                          <button 
                            onClick={openStats}
                            className="btn-settings btn-settings-stats" 
                            type="button" 
                            aria-pressed={isPressedSettingsStats ? 'true' : 'false'}
                            >
                            <span className="btn-settings__sr">Stats</span>
                          </button> 
                        </div>             
        </div>
        );
    };
    
    export default Home;